import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../../../components/MetaTags';
import { theme } from '../../../styles/theme';
import Background from '../../../components/Background';
import Header from '../../../components/Header2';
import HowGroupGiftsWork from '../../../sections/occasion/HowGroupGiftsWork';
import CuratedGifts from '../../../sections/occasion/CuratedGifts';
import GroupCard from '../../../sections/occasion/GroupCard';
import LoveIllume from '../../../sections/occasion/LoveIllume';
import JoinOccasions from '../../../sections/JoinOccasions';
import Footer from '../../../sections/common/FooterWithNav';
import data from '../../../sections/occasion/data';
import HeaderSection from '../../../sections/occasion/HeaderSection';
import futureCelebrating from '../../../images/occasion/birthday-relative.png';
import futurePhone from '../../../images/occasion/iPhone12.png';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionBirthdayRelative: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={
          'Create meaningful birthday ecards and gifts for anyone in your family with illume.'
        }
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={'Meaningful Birthday Gifts For The Family'}
          paragraphs={[
            'Your uncle’s birthday is coming up but your family is a bit spread out… Different schedules? Try different time zones! Don’t let that slow you down. Celebrate with a group gift from illume.',
            'Pick out a fun gift from illume’s digital marketplace, invite the whole family to contribute with cash, messages, photos, and more, and then hit send before the big day. Your uncle will wake up with a beautiful surprise – that easy.',
          ]}
          buttonLink={
            'https://web.illumenotes.com/receive?c=ef421165-9516-4bf0-b5e0-2ca2c205d75d&email=email@tryillume.com'
          }
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Birthday for a Relative',
            },
            phone: {
              image: futurePhone,
              label: 'Birthday for a Relative Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions joinMessage={'of celebrating birthdays'} />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionBirthdayRelative;
